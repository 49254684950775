(function(){
	var MEDIDAS = (function(){
		function MEDIDAS (){}

		MEDIDAS.prototype.define = function(medida, colection){
			this[medida] = {
				'gf-cm' 		: colection[0],
				'mN-m'  		: colection[1],
				'ozf-in'		: colection[2],
				'kgf-cm'		: colection[3],
				'cN-m(N·cm)' 	: colection[4],
				'lbf-in'		: colection[5],
				'kgf-m'			: colection[6],
				'N-m'			: colection[7],
				'lbf-ft'		: colection[8]
			}
		}

		return MEDIDAS;
	})();

	var medidas = new MEDIDAS();

	medidas.define('gf-cm', [
		1,
		0.0981,
		0.0139,
		0.001,
		0.00981,
		0.000868,
		0.00001,
		0.0000981,
		0.0000723
	]);

	medidas.define('mN-m', [
		10.2,
		1,
		0.142,
		0.0102,
		0.1,
		0.00885,
		0.000102,
		0.001,
		0.000738
	]);

	medidas.define('ozf-in', [
		72,
		7.06,
		1,
		0.072,
		0.706,
		0.0625,
		0.00072,
		0.00706,
		0.00521
	]);

	medidas.define('kgf-cm', [
		1000,
		98.1,
		13.9,
		1,
		9.81,
		0.868,
		0.01,
		0.0981,
		0.0723
	]);

	medidas.define('cN-m(N·cm)', [
		102,
		10,
		1.42,
		0.102,
		1,
		0.0885,
		0.00102,
		0.01,
		0.00738
	]);

	medidas.define('lbf-in', [
		1150,
		113,
		16,
		1.15,
		11.3,
		1,
		0.0115,
		0.113,
		0.0833
	]);

	medidas.define('kgf-m', [
		100000,
		9810,
		1390,
		100,
		981,
		86.8,
		1,
		9.81,
		7.23
	]);

	medidas.define('N-m', [
		10200,
		1000,
		142,
		10.2,
		100,
		8.85,
		0.102,
		1,
		0.738
	]);

	medidas.define('lbf-ft', [
		13800,
		1360,
		192,
		13.8,
		136,
		12,
		0.138,
		1.36,
		1
	]);

	$('.conversor-de-medidas [data-fn="converter"]').click(function(){
		var numBase = $('#number-origem').val() || 'gf-cm';
		var num = $('#input-conversor').val();

		num = num.replace(',','.');

		if(isNaN(num) == true){
			alert('Número inválido')
		}else{
			if(num == ''){
				num = 1;
				$('#input-conversor').val(1)
			}

			num = parseFloat(num);

			$('.conversor-de-medidas [data-medida]').each(function(){
				var m = $(this).data('medida');

				$(this).val(medidas[numBase][m] * num);
			});
		}

	});

	$('.conversor-de-medidas [data-fn="limpar"]').click(function(){
		$('.conversor-de-medidas input').val('');
		$('.conversor-de-medidas select').val('gf-cm');
	});
})();