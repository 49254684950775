(function($){
	$('.btn-toggle-menu').click(function(){
		var topo = document.querySelector('.topo');
		var bgTopo = document.createElement('div');

		$(bgTopo).addClass('bg-topo');

		$(bgTopo).click(function(){
			$(topo).removeClass('open');

			$(this).fadeOut(600, function(){
				$(this).remove();
			});
		});

		topo.appendChild(bgTopo);

		$(bgTopo).fadeIn(600);
		$(topo).addClass('open');
	});

	$('a[data-toggle="dropdown-menu"]').click(function(e){
		e.preventDefault();
		var parent = $(this).parents('.dropdown');

		if($(window).width() >= MEDIA.lg){
			parent.toggleClass('open');
		}else{
			parent.find('.collapse').collapse('toggle');
			parent.toggleClass('open');
		}
	});

	$(document).click(function(e){
		$('a[data-toggle="dropdown-menu"]').parents('.dropdown').removeClass('open');
	});

	$('.dropdown').click(function(e){
		e.stopPropagation();
	});
})(jQuery);


$('[data-toggle="collapse"]').click(function(){
	var target = $(this).data('target');

	$(target).collapse('toggle');
});