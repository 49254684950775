const DOMINIO = window.location.protocol + '//' + window.location.host;
const MEDIA = {
	xs : 575,
	sm : 576,
	md : 768,
	lg : 992,
	xl : 1200
};

String.prototype.toCamelCase = function() {
    return this.replace(/-/g,' ').replace(/^([A-Z])|\s(\w)/g, function(match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();        
    });
};

var COMPONENTES = (function(){
	function COMPONENTES(){
		this._COMPONENTES = {};
	}

	COMPONENTES.prototype.define = function(elemento,Classe){
		if(document.querySelector(elemento)){
			this._COMPONENTES[elemento.toCamelCase()] = [];

			var elementoList = document.querySelectorAll(elemento);

			for(var i=0 ; i<elementoList.length ; i++){
				this._COMPONENTES[elemento.toCamelCase()].push(new Classe(elementoList[i]));
			}
		}
	}

	COMPONENTES.prototype.log = function() {
		return this;
	};

	return COMPONENTES;
})();

var elementosCustom = new COMPONENTES();

$('table').wrap('<div class="table-responsive"></div>');