(function($){
	var jaAnimou = false;

	function AnimatedElementPosition(){
		$('[data-animate]').each(function(){
			var rect = this.getBoundingClientRect();
			var animation = $(this).data('animate');

			if(rect.top <= (window.innerHeight - 200)){
				$(this).addClass(animation);
			}
		});
	}

	$(window).scroll(function(){
		if(jaAnimou) return;

		setTimeout(function(){
			jaAnimou = false;
		},100);

		AnimatedElementPosition();
	});

	return AnimatedElementPosition();
})(jQuery);